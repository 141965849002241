import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import { ProductUSP } from './ProductUSP';
import ProductCTA from './ProductCTA';

import { isEmpty } from 'Shared/Common/Helpers';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import { Variant } from 'Enums/Variant.enum';
import { useEffect, useState } from 'react';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';

import SubscriptionOfferProductPageModel from 'Models/Pages/ProductPage/SubscriptionOfferProductPageModel.interface';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import IconBox from 'DesignComponents/Molecules/IconBox/IconBox';
import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';

type PropTypes = {
  productPage: SubscriptionOfferProductPageModel;
  openFirstAccordionItem?: () => void;
};

function SubscriptionAttributeArea({
  productPage,
  openFirstAccordionItem,
}: PropTypes) {
  const {
    productLabels: { shortDescriptionReadMoreText, productUsps },
  } = useTranslationData();
  const [variant, setVariant] = useState<VariationModel>();

  const { variationCollection, priceExplanation } = productPage;

  const { variantDispatch } = useVariantData();

  useEffect(() => {
    if (isEmpty(variationCollection)) return;

    const fallbackVariant = variationCollection[0];
    // get variant that is buyable, and is not price 0 and is the lowest price

    const defaultVariant = variationCollection
      .filter((variant) => variant.nowPrice.price !== 0 && variant.isBuyable)
      .reduce<null | VariationModel>((prev, curr) => {
        if (prev === null) return curr;

        return prev.nowPrice.price < curr.nowPrice.price ? prev : curr;
      }, null);

    setVariant(defaultVariant ?? fallbackVariant);

    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: defaultVariant ?? fallbackVariant,
      hasSizes: false,
    });
  }, [variationCollection]);

  return (
    <AttributeContainer>
      <AttributeWrapper>
        <Heading size={'l'} tag="h1" color="Regular" css={{ mb: '$s100' }}>
          {productPage.displayName}
          {productPage.nameOfAdditionalItems && (
            <SubHeading>{'+ ' + productPage.nameOfAdditionalItems}</SubHeading>
          )}
        </Heading>
        {productPage.shortDescription && (
          <BodyText size="l">
            {productPage.shortDescription}
            {productPage.hasLongDescription && (
              <>
                {' '}
                <ReadMore
                  onClick={(e) => {
                    e.preventDefault();
                    if (openFirstAccordionItem) {
                      openFirstAccordionItem();
                    }
                  }}
                >
                  {shortDescriptionReadMoreText}
                </ReadMore>
              </>
            )}
          </BodyText>
        )}
        <ProductCTA />
        <ProductBoxes>
          {variant?.freightAdditionalInfo && (
            <IconBox
              icon="Shipping"
              title={variant.freightAdditionalInfo}
            ></IconBox>
          )}
          {variant && (
            <IconBox
              icon="Subscription"
              title={variant.additionalInfo}
            ></IconBox>
          )}
          <IconBox
            icon="Price"
            title={'<h4>' + priceExplanation.priceCalculationHeader + '</h4>'}
            opened
          >
            <Table>
              <caption>{priceExplanation.priceCalculationHeader}</caption>
              <tbody>
                <tr>
                  <th scope="row">{priceExplanation.offerIssuesLabel}</th>
                  <td>
                    {priceExplanation.offerIssuesPrice.priceAsString}{' '}
                    <Currency>
                      {priceExplanation.offerIssuesPrice.currencySymbol}
                    </Currency>
                  </td>
                </tr>
                {priceExplanation.premiumLabel && (
                  <tr>
                    <th scope="row">{priceExplanation.premiumLabel}</th>
                    <td>
                      {priceExplanation.premiumPrice.priceAsString}{' '}
                      <Currency>
                        {priceExplanation.offerIssuesPrice.currencySymbol}
                      </Currency>
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row">{priceExplanation.totalAmountLabel}</th>
                  <td>
                    {priceExplanation.totalAmount.priceAsString}{' '}
                    <Currency>
                      {priceExplanation.offerIssuesPrice.currencySymbol}
                    </Currency>
                  </td>
                </tr>
                {priceExplanation.discountAmount.price > 0 && (
                  <DiscountRow>
                    <th scope="row">{priceExplanation.discountLabel}</th>
                    <td>
                      -{priceExplanation.discountAmount.priceAsString}{' '}
                      <Currency>
                        {priceExplanation.offerIssuesPrice.currencySymbol}
                      </Currency>
                    </td>
                  </DiscountRow>
                )}
                <tr>
                  <th scope="row">
                    <WeightSemiBold>
                      {priceExplanation.priceLabel}
                    </WeightSemiBold>
                  </th>
                  <td>
                    <WeightSemiBold>
                      {priceExplanation.price.priceAsString}{' '}
                      <Currency>
                        {priceExplanation.offerIssuesPrice.currencySymbol}
                      </Currency>
                    </WeightSemiBold>
                  </td>
                </tr>
              </tbody>
            </Table>
          </IconBox>

          <ProductUSP
            productUsp={productPage.usp}
            globalUsp={productPage.globalUsp}
            label={productUsps}
          />
        </ProductBoxes>
      </AttributeWrapper>
    </AttributeContainer>
  );
}

const SubHeading = styled('div', {
  fontSize: '$fontSize500',
  fontFamily: 'fontSemiBold',
  color: '$onSurface',
});

const AttributeContainer = styled('div', {});

const AttributeWrapper = styled('div', {
  w: '100%',
});

const ReadMore = styled('a', {
  color: '$emphasisedLinkColor',
  fontFamily: 'fontSemiBold',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    cursor: 'pointer',
  },
});

const ProductBoxes = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const Table = styled('table', {
  width: '100%',
  '& th, td': {
    pb: '12px',
    textAlign: 'left',
    fontWeight: '$fontWeightRegular',
  },
  '& tr:last-child :where(td, th)': {
    pt: '20px',
  },
  '& tr td:last-child': {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  ' & caption': {
    ...SrOnlyStyle,
  },
});

const DiscountRow = styled('tr', {
  c: 'semanticError',
});

const Currency = styled('span', {
  fontSize: '$fontSize100',
});

const WeightSemiBold = styled('span', {
  fontFamily: 'fontSemiBold',
  color: '$onSurface',
});

export default SubscriptionAttributeArea;
